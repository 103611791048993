import styled from 'styled-components';
import GlobalStyle from './GlobalStyle';
import Header from './Header.js';
import impressum from '../images/imprint.png';
import studio from '../images/studio-scaled.jpg';

export default function Content(){
 
    return(
<div id="ContentWrapper"> 
<GlobalStyle />  
<Header id="top" /> 
<Section1>  
<p class="pagination">01</p> 
<h1 class="animated-text">infinite Sequences</h1>
<p>Oliver Starckjohann: Online Marketing / KPI + SEO + UX + UI Optimizer / Bachelor in Mediamarketing / Artisan</p><br />
</Section1>
<Section4 id="about">
<p class="pagination">02</p>   
<h1>About Me</h1>    
<h3><TopAnchor href="https://oliver-starckjohann.de/music/" target="_new">Electronic Music</TopAnchor></h3>
<h3><TopAnchor href="https://themotosocial.com/hamburg-deutsch" target="_new">Moto - Culture</TopAnchor></h3>
<h3><TopAnchor href="https://www.surfinbiarritz.com/" target="_new">Surfing</TopAnchor></h3>
<h3><TopAnchor href="https://oliver-starckjohann.de/dresstoday/" target="_new">FashionApp - Demo</TopAnchor></h3>
<h3><TopAnchor href="https://www.visitberlin.de/de/urban-art-berlin" target="_new">Urban Art Berlin</TopAnchor></h3>
<p>Electronic Music / Moto - Culture / Surfing / Art / Fashion / Design</p>
</Section4>
<Section5 id="vita">
<p class="pagination">03</p>   
<h1>Vita</h1> 
<Textwrap> 
<p>Oliver Starckjohann, familiäre Wurzeln in Berlin, Studium in Medienmarketing, Webdeveloper Bootcamp.</p>
<Spacer></Spacer>
<p>W E R D E G A N G:</p>
<Spacer></Spacer>
<p>Mein Mac war seit 1991 mein Hobby, 1995 kam ich zu CompuServe, einem der ersten Onlinedienste in Deutschland. 
    Hier habe ich den technischen Aufbau des Internet gelernt sowie erste Erfahrungen mit Content-Strategien in der 
    Rolle eines Produktmanagers für Communities gesammelt.</p>
    <Spacer></Spacer>
<p>Nach einem Medienmarketing Studium an der BAW war ich Drei Jahre beim Bayerischen Rundfunk im Bereich Content 
    und Strategie beschäftigt, bevor ich bei Yahoo! die Verantwortung für den Customer Care übernommen habe.</p>
<Spacer></Spacer>
<p>2006 kam ich nach Hamburg in den Bereich Online Advertising als AdManager zum QC, dem Vermarkter des SPIEGEL 
    Verlag. Hier betreute ich die Titel des Conde Nast Verlages (VOGUE, Glamour, Myself).</p>
<Spacer></Spacer>
<p>2009 wechselte ich zu Gruner + Jahr und betreute dort als AdTechnology Manager die Onlinvermarktung der Titel 
    stern.de, brigitte.de und eltern.de.</p>
<Spacer></Spacer>
<p>2015 Startup-Experience im Bereich Audiovermarktung (adremes). Hier baute ich den Bereich Technical Services auf, 
    und konnte viel über Markenstrategie, Positionierung und Service als USP lernen.</p>
<Spacer></Spacer>
<p>2020 wechselte ich in ein wunderbares, kreatives Team ins PRODEERS lab, der Production Unit der Hirschen Group. 
    Hier habe ich als Website Manager Sites der Agenturgruppe umgesetzt, UX und SEO optimiert.</p>
<Spacer></Spacer>
<p>Seit 2023 bin ich im SEO & Content Marketing Team des Jungheinrich Profi- Shop in Hamburg – hier optimieren 
    wir die Customer - Journey und pflegen den Garten der KPIs und Marken des Webshop.</p>
<Spacer></Spacer>
<p>A N A L Y Z E - O P T I M I Z E - R E F L E C T - R E P E A T - -</p>
</Textwrap>  
</Section5>
<Section6 id="imprint"> 
<p class="pagination">04</p>    
<h1>Impressum /<br /> Imprint</h1>
<img src={impressum} alt="Bild mit Text" />

<p> - - </p>
<p>Bitte kontaktiert mich gerne auf:</p>
<p> <TopAnchor href="https://de.linkedin.com/in/oliver-starckjohann" target="_new">Linkedin</TopAnchor> / <TopAnchor href="https://github.com/oliverstarckjohann" target="_new">GitHub</TopAnchor></p>
<p> - - </p>
<p>Keine Haftung für weiterführende Links:</p>
<Textwrap>
<p>Die Website enthält Links zu Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. 
    Diese Links stellen keine Zustimmung zu den Inhalten dieser Websites seitens Oliver Starckjohann dar. 
    Für die Verfügbarkeit und den Inhalt der verlinkten Websites sind ausschließlich deren Betreiber:innen verantwortlich.
</p>
</Textwrap>
</Section6>
</div>
);
}

const Section1 = styled.section`
    display: block;
    height: auto;
    width: 98%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 60px;
    padding-bottom: 60px;
    color: #ffffff;
    background-color: #1aa7d5;
    word-wrap: break-word;
    overflow-y: auto;
    overflow-x: hidden;
`
const Section2 = styled.section`
    display: block;
    height: auto;
    width: 98%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 60px;
    padding-bottom: 60px;
    color: #ffffff;
    background-color: #AB218E;
    word-wrap: break-word;
    overflow-y: auto;
`
const Section3 = styled.section`
    display: block;
    height: auto;
    width: 98%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 60px;
    padding-bottom: 60px;
    color: #ffffff;
    background-color: #d55b0a;
    word-wrap: break-word;
    overflow-y: auto;
`
const Section4 = styled.section`
    display: block;
    height: auto;
    width: 98%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 60px;
    padding-bottom: 60px;
    color: #ffffff;
    background-color: #e71336;
    word-wrap: break-word;
    overflow-y: auto;
`
const Section5 = styled.section`
    display: block;
    height: auto;
    width: 98%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 60px;
    padding-bottom: 60px;
    color: #D7FF80;
    background-color: #C905FF;
    word-wrap: break-word;
    overflow-y: auto;
    overflow-x: hidden;
`
const Section6 = styled.section`
    display: block;
    height: auto;
    width: 98%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 60px;
    padding-bottom: 60px;
    color: #ffffff;
    background-image: url(${studio});
    background-size: cover;
    background-repeat: no-repeat;
    word-wrap: break-word;
    overflow-y: auto;
    overflow-x: hidden;
`
const Spacer = styled.div`
    display: block;
    height: 1rem;
    width: 1rem;
`
const Textwrap = styled.div`
    display: block;
    height: auto;
    width: 98%;
`

const TopAnchor = styled.a`
    color: #7dd7f5;
    text-decoration: none;
    `